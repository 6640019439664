<template>
  <div>
    <div class="button-box">
      <el-button size="small"
        :disabled="multipleSelection.length == 0"
        @click="handleClearSelection()">取消选择</el-button>
      <el-button size="small"
        :disabled="multipleSelection.length == 0"
        @click="handleDeleteSelection()">删除</el-button>
    </div>
    <el-table
      ref="listTable"
      :data="tableData"
      @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        fixed="left"
        align="center"
        width="55" />
      <el-table-column
        prop="entrance"
        label="渠道入口"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ handleState(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="channel"
        label="线路"
        min-width="150"
        align="center" />
      <el-table-column
        prop="domain"
        label="线路域名"
        min-width="150"
        align="center" />
      <el-table-column
        label="状态"
        fixed="right"
        min-width="100"
        align="center">
        <template slot-scope="scope">
          <el-switch
            @change="handleChangeState($event, scope.row)"
            :disabled="!editable"
            v-model="scope.row.is_visible"
            active-value="1"
            inactive-value="0" />
          {{ scope.row.is_visible === "1" ? "上架" : "下架" }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        min-width="200"
        align="center">
        <template slot-scope="scope">
          <el-button-group>
            <el-tooltip
              effect="dark"
              content="修改"
              placement="top">
              <el-button
                :disabled="!editable"
                type="success"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)" />
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="刪除"
              placement="top">
              <el-button
                :disabled="!removal"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)" />
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { domainChangeStatus } from '@/api/admin/domain'
import options from '@/utils/options'

export default {
  data () {
    return {
      multipleSelection: [],
    }
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    removal: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleState(row) {
      let data = ``
      options.entranceList.forEach(key => {
        if (key.value === row.entrance)
          data = key.label
      })
      return data
    },
    handleChangeState ($event, row) {
      this.handleEditable(false)
      const status = $event
      domainChangeStatus(row.id, status)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$emit('handleRefresh')
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
          this.handleEditable(true)
        })
        .catch(err => {
          this.$message.error(err)
          this.handleEditable(true)
        })
    },
    handleEditable (boo) {
      this.$emit('handleEditable', boo)
    },
    handleEdit (row) {
      this.$router.push({
        name: 'AdminMarketDomainEdit',
        params: {
          id: row.id
        }
      })
    },
    handleDelete (row) {
      this.$emit('handleDelete', row.id)
    },
    handleDeleteSelection () {
      const idList = this.multipleSelection.map((item) => {
        return item.id
      })
      this.$emit('handleDelete', idList.join(','))
    },
    handleClearSelection () {
      this.$refs.listTable.clearSelection()
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
  }
}
</script>

<style lang="scss" scoped>
  .button-box{
    text-align: left;
    margin: 20px 0 10px;
  }
  .table-richtext-col{
    max-height: 140px;
    overflow-y: scroll;
    transform: scale(0.85);
  }
</style>
